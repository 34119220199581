import axios from 'axios';
import { getToken, getUserRole } from '../lib/Helper/helper';

const potentialSubdomain = window.location.hostname
  .split('.')
  .slice(0, -2)
  .shift();

const [subdomain, rest] = potentialSubdomain?.split('-') ?? [];
if (!subdomain) {
  window.location.href = '/404.html';
}

const isStaging = rest === 'staging';
const backendBaseUrl = isStaging
  ? process.env.REACT_APP_CORRSY_BACKEND_STAGING
  : process.env.REACT_APP_CORRSY_BACKEND_PRODUCTION;

const localProtocol = process.env.REACT_APP_CORRSY_BACKEND_LOCAL_PROTOCOL;

export const oldBackend = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? `https://${subdomain}.${backendBaseUrl}`
      : `${localProtocol}://${subdomain}${process.env.REACT_APP_CORRSY_OLD_BACKEND_LOCAL}`,
});

export const newBackend = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? `https://${subdomain}-lessonapi.${backendBaseUrl}`
      : `${localProtocol}://${subdomain}${process.env.REACT_APP_CORRSY_NEW_BACKEND_LOCAL}`,
});

oldBackend.interceptors.request.use(
  (config) => {
    const token: string = getToken()!;
    if (token) {
      config.headers = { Authorization: `bearer ${token}` };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

newBackend.interceptors.request.use(
  (config) => {
    const token: string = getToken()!;
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        role: getUserRole(),
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);
